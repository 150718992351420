.container {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.image {
  height: 99.3vh;
  width: 100vw;
}

.logo {
  position: absolute;
  z-index: 1;
  top: 5%;
  height: 20%;
}

.h1 {
  position: absolute;
  z-index: 1;
  font-size: 40px;
  top: 48%;
  left: 50%;
  font-weight: 500;
  font-style: normal;
  transform: translate(-50%, -50%);
}

.h3 {
  position: absolute;
  z-index: 1;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.h4 {
  position: absolute;
  z-index: 1;
  font-weight: 600;
  font-size: 15px;
  left: 50%;
  top: 95%;
  transform: translate(-50%, -50%);
}

@media (max-width: 480px) {
  .image {
    height: 99.5vh;
    width: 100vw;
  }

  .logo {
    height: 10%;
  }

  .h1 {
    text-align: center;
    font-weight: 400;
    font-size: 22px;
  }

  .h3 {
    text-align: center;
    font-size: 15px;
  }

  .h4 {
    text-align: center;
    font-size: 12px;
  }
}
